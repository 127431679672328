@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.HomeMainCarousel {
  background-color: map.get($theme-colors, "extra-dark");
  padding: 0 2.5rem;
  position: relative;
  @include mulish-regular;

  @include breakpoint-under("lg") {
    padding: 0 1rem;
    max-width: 1287px;
    overflow: hidden;
  }

  @include breakpoint-under("md") {
    padding: 0 1rem;
    // height: 725px;
  }

  .thumbContainer {
    margin-right: 2rem;
  }
  .thumbPicture {
    position: absolute;
    bottom: -400px;
    z-index: 2;
    opacity: 0;
    transition: all 0.5s ease;
    left: calc(((100% - 1200px) / 2) + 1000px);

    @include breakpoint-under("xxxl") {
      left: calc(100% - 235px);
    }

    @include breakpoint-under("lg") {
      bottom: -290px;
    }

    @include breakpoint-under("md") {
      display: none;
    }

    img {
      width: 300px;
      height: 150px;
      object-fit: cover;
    }
  }

  .thumbIndexContainer {
    position: absolute;
    border: 1px dashed map.get($theme-colors, "gold-regular");
    border-radius: 200%;
    left: calc(((100% - 1200px) / 2) + 960px);
    z-index: 3;
    bottom: -475px;
    box-sizing: border-box;
    padding: 0.25rem;

    @include breakpoint-under("xxxl") {
      left: calc(100% - 275px);
    }

    @include breakpoint-under("lg") {
      bottom: -365px;
    }

    @include breakpoint-under("md") {
      display: none;
    }
  }

  .thumbInfoContainer {
    position: absolute;
    left: calc(((100% - 1200px) / 2) + 1075px);
    z-index: 3;
    top: 410px;
    box-sizing: border-box;
    padding: 0.25rem;
    transition: all 0.5s ease;
    opacity: 0;

    @include breakpoint-under("xxxl") {
      left: calc(100% - 160px);
    }

    @include breakpoint-under("lg") {
      top: 310px;
    }

    @include breakpoint-under("md") {
      display: none;
    }

    .nextText {
      font-size: 1.25rem;
      @include unna-italic;
      margin-top: 0.3rem;
      color: map.get($theme-colors, "light");
    }
  }

  .thumbContainer + .thumbContainer > .thumbInfoContainer {
    display: none;
  }

  .selected {
    opacity: 1;
  }

  .carouselSwiper {
    width: 100%;
  }

  .carouselSlideContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px; // al massimo largo quanto le immagini
    margin: auto;

    .currentImageContainer {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        background: linear-gradient(
          to top,
          map.get($theme-colors, "extra-dark") 0.24%,
          transparent 83.55%
        );
        width: 100%;
        height: 100%;
        bottom: 0;
      }
    }
  }

  .carouselSlideInfo {
    align-self: flex-start;
    margin-left: 5rem;
    margin-top: 1rem;
    margin-right: 350px;
    transition: all 0.5s ease;
    opacity: 0;

    @include breakpoint-under("lg") {
      margin-left: 0rem;
    }

    @include breakpoint-under("md") {
      margin-left: 0rem;
      opacity: 1;
    }

    @include breakpoint-under("md") {
      margin-right: 0;
      position: relative;
      margin-top: 3.2rem;
    }

    .infoType {
      color: map.get($theme-colors, "gold");
      font-size: 1rem;
    }

    .infoDesc {
      color: map.get($theme-colors, "light");
      font-size: 2.25rem;
      @include unna-italic;
      @include breakpoint-under("sm") {
        font-size: 1.8rem;
        line-height: 1.1em;
      }
    }

    .infoLink {
      font-size: 1rem;
      margin-top: 1rem;
      @include unna-italic;
      cursor: pointer;
      align-items: center;
      * {
        text-decoration: none;
      }

      .linkContent {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.5s ease;
        color: map.get($theme-colors, "gold");
        path{
          transition: all 0.5s ease;
        }

        &:hover {
          color: map.get($theme-colors, "light");
          path {
            stroke: map.get($theme-colors, "light");
          }
        }
      }
    }
  }
  .disappear {
    opacity: 1;
  }
}
